import SP2InfoListItem from "./SP2InfoListItem";
import { getSP2InfoNames } from "../../utils/displayUtil";
import "./SP2InfoList.css";

export default function SP2InfoList({
  houseName,
  cropName,
  plantingDate,
  displaySetting
}) {

  const infoListItems = getSP2InfoNames(cropName).map((infoName, i) => {
    return (
      <SP2InfoListItem
        key={houseName + "-" + infoName}
        houseName={houseName}
        infoName={infoName}
        cropName={cropName}
        plantingDate={plantingDate}
        displaySetting={displaySetting}
      />
    );
  });

  return (
    <ul className="sp2-info-list">
      {infoListItems}
    </ul>
  );
};
