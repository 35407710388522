export const convertToVaporPressureDeficit = (airTemperature, relativeHumidity) => {
  if (!Number.isFinite(airTemperature) || !Number.isFinite(relativeHumidity)) return null;

  // 飽和水蒸気圧(hPa)
  const vaporPressureOfWater = 6.1078 * 10 ** (7.5 * airTemperature / (airTemperature + 237.3));

  // 飽和水蒸気量(g/m3)
  const amountOfSaturatedWaterVapor = 217 * vaporPressureOfWater / (airTemperature + 273.15);

  // 飽差(g/m3)
  const vaporPressureDeficit = (100 - relativeHumidity) * amountOfSaturatedWaterVapor / 100;

  return vaporPressureDeficit;
}

export const getTotalValueOfFruits = data => {
  if (!data) return null;
  if (!Number.isFinite(data.unripe_fruits) && !Number.isFinite(data.ripe_fruits)) return null;
  return data.unripe_fruits + data.ripe_fruits;
};
