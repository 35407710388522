import { getSP2InfoNames } from "../../utils/displayUtil";
import CurrentValueSP2InfoListItem from "./CurrentValueSP2InfoListItem";

const INFO_NAMES = [
  "photosynthetic_rate",
  "transpiration_rate",
  "lai",
  "leaf_temperature",
  "flowers_and_fruits",
];

const buildInfoNames = cropName => {
  const sp2InfoNames = getSP2InfoNames(cropName);
  const intersection = INFO_NAMES.filter(infoName => sp2InfoNames.includes(infoName));
  const result = new Set();
  for (const infoName of intersection) {
    if (infoName === "unripe_fruits" || infoName === "ripe_fruits") {
      result.add("fruits");
    } else {
      result.add(infoName);
    }
  }
  return Array.from(result);
};

export default function CurrentValueSP2InfoList({
  groupName,
  houseName,
  cameraName,
  laiCameraName,
  flowersAndFruitsCameraName,
  cropName,
  plantingDate,
  displaySetting
}) {

  const infoListItems = buildInfoNames(cropName).map(infoName => {
    let _cameraName = cameraName;
    if (infoName === "lai") _cameraName = laiCameraName;
    if (infoName === "flowers_and_fruits") _cameraName = flowersAndFruitsCameraName;
    return (
      <CurrentValueSP2InfoListItem
        key={houseName + "-" + infoName}
        groupName={groupName}
        houseName={houseName}
        cameraName={_cameraName}
        infoName={infoName}
        cropName={cropName}
        plantingDate={plantingDate}
        displaySetting={displaySetting}
      />
    );
  });

  return (
    <ul>
      {infoListItems}
    </ul>
  );
};
