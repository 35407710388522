import useLatestSP2DBInfo from "../../hooks/use-latest-sp2-db-info";
import { displayValue, getSP2InfoNameWithCanopyApplied } from "../../utils/displayUtil";
import { getTotalValueOfFruits } from "../../utils/calcUtil";

import "./CurrentValueSP2InfoListItem.css";
import icon_lai from "../../assets/sp2_info/sp2_info_icon_lai_1.png";
import icon_leaf_temperature from "../../assets/sp2_info/sp2_info_icon_leaf_temperature_1.png";
import icon_photosynthetic_rate from "../../assets/sp2_info/sp2_info_icon_photosynthetic_rate_1.png";
import icon_transpiration_rate from "../../assets/sp2_info/sp2_info_icon_transpiration_rate_1.png";
import icon_flowers from "../../assets/sp2_info/sp2_info_icon_flowers_1.png";
import icon_fruits_bell_pepper from "../../assets/sp2_info/sp2_info_icon_fruits_bell_pepper_1.png";
import icon_fruits_corn from "../../assets/sp2_info/sp2_info_icon_fruits_corn_1.png";
import icon_fruits_cucumber from "../../assets/sp2_info/sp2_info_icon_fruits_cucumber_1.png";
import icon_fruits_eggplant from "../../assets/sp2_info/sp2_info_icon_fruits_eggplant_1.png";
import icon_fruits_grape from "../../assets/sp2_info/sp2_info_icon_fruits_grape_1.png";
import icon_fruits_green_pepper from "../../assets/sp2_info/sp2_info_icon_fruits_green_pepper_1.png";
import icon_fruits_green_pepper_s from "../../assets/sp2_info/sp2_info_icon_fruits_green_pepper_s_1.png";
import icon_fruits_mandarin_orange from "../../assets/sp2_info/sp2_info_icon_fruits_mandarin_orange_1.png";
import icon_fruits_melon from "../../assets/sp2_info/sp2_info_icon_fruits_melon_1.png";
import icon_fruits_strawberry from "../../assets/sp2_info/sp2_info_icon_fruits_strawberry_1.png";
import icon_fruits_tomato from "../../assets/sp2_info/sp2_info_icon_fruits_tomato_1.png";

const STYLES = {
  lai: {
    displayName: "LAI",
    unit: "m2/m2",
    icon: icon_lai,
    color: "#39B54A",
    digits: 1,
  },
  leaf_temperature: {
    displayName: "葉温",
    unit: "℃",
    icon: icon_leaf_temperature,
    color: "#D4686C",
    digits: 1,
  },
  photosynthetic_rate: {
    displayName: "光合成速度",
    unit: "µmol/m2/s",
    icon: icon_photosynthetic_rate,
    color: "#FF1D25",
    digits: 1,
  },
  transpiration_rate: {
    displayName: "蒸散速度",
    unit: "mmol/m2/s",
    icon: icon_transpiration_rate,
    color: "#3FA9F5",
    digits: 1,
  },
  flowers: {
    displayName: "開花数",
    unit: "個",
    icon: icon_flowers,
    color: "#FF7BAC",
    digits: 1,
  },
  fruits: {
    displayName: "着果数",
    unit: "個",
    icon: {
      "bell-pepper": icon_fruits_bell_pepper,
      "corn": icon_fruits_corn,
      "cucumber": icon_fruits_cucumber,
      "eggplant": icon_fruits_eggplant,
      "grape": icon_fruits_grape,
      "green-pepper": icon_fruits_green_pepper,
      "green-pepper-s": icon_fruits_green_pepper_s,
      "mandarin-orange": icon_fruits_mandarin_orange,
      "melon": icon_fruits_melon,
      "strawberry": icon_fruits_strawberry,
      "tomato": icon_fruits_tomato,
    },
    color: {
      "bell-pepper": "#FF931D",
      "corn": "#459B6C",
      "cucumber": "#459B6C",
      "eggplant": "#B78DDB",
      "grape": "#B78DDB",
      "green-pepper": "#459B6C",
      "green-pepper-s": "#459B6C",
      "mandarin-orange": "#FBB03B",
      "melon": "#459B6C",
      "strawberry": "#FF777B",
      "tomato": "#FF777B",
    },
    digits: 1,
  }
};

const BaseComponent = ({ icon, displayName, color, value, digits, unit }) => (
  <li className="current-value-sp2-info-list-item-container">
    <div className="current-value-sp2-info-list-item-icon">
      <img src={icon} alt={displayName} width="100%" />
    </div>
    <div className="current-value-sp2-info-list-item-content">
      <div
        className="current-value-sp2-info-list-item-value"
        style={{ color }}
      >
        {displayValue(value, digits)} <span className="current-value-sp2-info-list-item-value-unit">{unit}</span>
      </div>
    </div>
  </li>
);

export default function CurrentValueSP2InfoListItem({ infoName, houseName, cropName, plantingDate, displaySetting }) {

  const { value } = useLatestSP2DBInfo(getSP2InfoNameWithCanopyApplied(displaySetting, infoName), houseName, plantingDate);

  if (infoName === "flowers_and_fruits") {
    return (
      <>
        <BaseComponent
          icon={STYLES["flowers"].icon}
          displayName={STYLES["flowers"].displayName}
          color={STYLES["flowers"].color}
          value={value && value.flowers}
          digits={STYLES["flowers"].digits}
          unit={STYLES["flowers"].unit}
        />
        <BaseComponent
          icon={STYLES["fruits"].icon[cropName]}
          displayName={STYLES["fruits"].displayName}
          color={STYLES["fruits"].color[cropName]}
          value={getTotalValueOfFruits(value)}
          digits={STYLES["fruits"].digits}
          unit={STYLES["fruits"].unit}
        />
      </>
    );
  } else {
    const { icon, displayName, color, digits, unit } = STYLES[infoName];
    return (
      <BaseComponent
        icon={icon}
        displayName={displayName}
        color={color}
        value={value}
        digits={digits}
        unit={unit}
      />
    );
  }
}
