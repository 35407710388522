import { startOfDay } from "date-fns";
import client from "./client";

const fetchAll = async (groupName) => {
  try {
    const response = await client.get(`/view/${groupName}/houses`);
    return response.data.map(house => {
      return {
        name: house.name,
        displayName: house.display_name,
        simulationCo2Concentration: house.simulation_co2_concentration,
        simulationAirTemperature: house.simulation_air_temperature,
        arableLandArea: house.arable_land_area,
        displaySetting: house.display_setting,
        cameras: house.cameras.map(({ name, display_name }) => ({
          name,
          displayName: display_name,
        })),
        cultivation: {
          plantingYear: house.cultivation.planting_year,
          plantingDate: startOfDay(new Date(house.cultivation.planting_date)),
          crop: {
            name: house.cultivation.crop.name,
            displayName: house.cultivation.crop.display_name
          }
        },
        irrigationSettings: house.irrigation_settings.reduce((accumulator, item) => {
          accumulator[item.crop.name] = {
            idealIrrigationCoefficient: item.ideal_irrigation_coefficient,
            plantingDensity: item.planting_density,
            solarIrradianceProportionalWaterAmount: item.solar_irradiance_proportional_water_amount,
          };
          return accumulator;
        }, {}),
        heaters: house.heaters.map(({ name, display_name, fuel_consumption }) => ({
          name,
          displayName: display_name,
          fuelConsumption: fuel_consumption
        })),
        displayOrder: house.display_order,
        aiEngineSetting: house.ai_engine_setting && {
          laiCameraName: house.ai_engine_setting.lai_camera_name,
          flowersAndFruitsCameraName: house.ai_engine_setting.flowers_and_fruits_camera_name,
        }
      };
    });
  } catch (error) {
    throw error;
  }
}

const viewHouseApi = {
  fetchAll
};

export default viewHouseApi;
