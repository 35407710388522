const SP2_INFO_NAME_LIST = {
  "eggplant": [
    "lai",
    "photosynthetic_rate",
    "fruit_load",
    "transpiration_rate",
    "leaf_temperature",
    "flowers_and_fruits",
  ],
  "chinese-chive": [
    "lai",
    "photosynthetic_rate",
    "fresh_weight",
    "transpiration_rate",
    "leaf_length",
    "leaf_temperature",
  ],
  "bell-pepper": [
    "lai",
    "photosynthetic_rate",
    "fruit_load",
    "transpiration_rate",
    "leaf_temperature",
    "flowers_and_fruits",
  ],
  "tomato": [
    "lai",
    "photosynthetic_rate",
    "fruit_load",
    "transpiration_rate",
    "leaf_temperature",
    "flowers_and_fruits",
  ],
  "melon": [
    "lai",
    "photosynthetic_rate",
    "fruit_load",
    "transpiration_rate",
    "leaf_temperature",
    "flowers_and_fruits",
  ],
  "grape": [
    "lai",
    "photosynthetic_rate",
    "fruit_load",
    "transpiration_rate",
    "leaf_temperature",
    "flowers_and_fruits",
  ],
  "spinach": [
    "lai",
    "photosynthetic_rate",
    "fresh_weight",
    "transpiration_rate",
    "leaf_length",
    "leaf_temperature",
  ],
  "wasabi": [
    "lai",
    "photosynthetic_rate",
    "fresh_weight",
    "transpiration_rate",
    "leaf_length",
    "leaf_temperature",
  ],
  "strawberry": [
    "lai",
    "photosynthetic_rate",
    "fruit_load",
    "transpiration_rate",
    "leaf_temperature",
    "flowers_and_fruits",
  ],
  "cucumber": [
    "lai",
    "photosynthetic_rate",
    "fruit_load",
    "transpiration_rate",
    "leaf_temperature",
    "flowers_and_fruits",
  ],
  "green-pepper": [
    "lai",
    "photosynthetic_rate",
    "fruit_load",
    "transpiration_rate",
    "leaf_temperature",
    "flowers_and_fruits",
  ],
  "green-pepper-s": [
    "lai",
    "photosynthetic_rate",
    "fruit_load",
    "transpiration_rate",
    "leaf_temperature",
    "flowers_and_fruits",
  ],
  "corn": [
    "lai",
    "photosynthetic_rate",
    "fruit_load",
    "transpiration_rate",
    "leaf_temperature",
    "flowers_and_fruits",
  ],
  "mandarin-orange": [
    "lai",
    "photosynthetic_rate",
    "fruit_load",
    "transpiration_rate",
    "leaf_temperature",
    "flowers_and_fruits",
  ],
};

export const displayValue = (value, digits = 1) => Number.isFinite(value) ? Number.parseFloat(value).toFixed(digits) : "N/A";
export const getSP2InfoNames = cropName => SP2_INFO_NAME_LIST[cropName];
