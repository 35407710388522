import SP2InfoList from "./SP2InfoList";

import "./SP2Info.css";

import icon_eggplant from "../../assets/title_icon_eggplant.png";
import icon_chinese_chive from "../../assets/title_icon_chinese_chive.png";
import icon_bell_pepper from "../../assets/title_icon_bell_pepper.png";
import icon_tomato from "../../assets/title_icon_tomato.png";
import icon_melon from "../../assets/title_icon_melon.png";
import icon_grape from "../../assets/title_icon_grape.png";
import icon_spinach from "../../assets/title_icon_spinach.png";
import icon_wasabi from "../../assets/title_icon_wasabi.png";
import icon_strawberry from "../../assets/title_icon_strawberry.png";
import icon_cucumber from "../../assets/title_icon_cucumber.png";
import icon_green_pepper from "../../assets/title_icon_green_pepper.png";
import icon_green_pepper_s from "../../assets/title_icon_green_pepper_s.png";
import icon_corn from "../../assets/title_icon_corn.png";
import icon_mandarin_orange from "../../assets/title_icon_mandarin_orange.png";

import crop_eggplant from "../../assets/eggplant.png";
import crop_chinese_chive from "../../assets/chinese_chive.png";
import crop_bell_pepper from "../../assets/bell_pepper.png";
import crop_tomato from "../../assets/tomato.png";
import crop_melon from "../../assets/melon.png";
import crop_grape from "../../assets/grape.png";
import crop_spinach from "../../assets/spinach.png";
import crop_wasabi from "../../assets/wasabi.png";
import crop_strawberry from "../../assets/strawberry.png";
import crop_cucumber from "../../assets/cucumber.png";
import crop_green_pepper from "../../assets/green_pepper.png";
import crop_green_pepper_s from "../../assets/green_pepper_s.png";
import crop_corn from "../../assets/corn.png";
import crop_mandarin_orange from "../../assets/mandarin_orange.png";

const ICONS = {
  "eggplant": icon_eggplant,
  "chinese-chive": icon_chinese_chive,
  "bell-pepper": icon_bell_pepper,
  "tomato": icon_tomato,
  "melon": icon_melon,
  "grape": icon_grape,
  "spinach": icon_spinach,
  "wasabi": icon_wasabi,
  "strawberry": icon_strawberry,
  "cucumber": icon_cucumber,
  "green-pepper": icon_green_pepper,
  "green-pepper-s": icon_green_pepper_s,
  "corn": icon_corn,
  "mandarin-orange": icon_mandarin_orange,
};

const CROPS = {
  "eggplant": crop_eggplant,
  "chinese-chive": crop_chinese_chive,
  "bell-pepper": crop_bell_pepper,
  "tomato": crop_tomato,
  "melon": crop_melon,
  "grape": crop_grape,
  "spinach": crop_spinach,
  "wasabi": crop_wasabi,
  "strawberry": crop_strawberry,
  "cucumber": crop_cucumber,
  "green-pepper": crop_green_pepper,
  "green-pepper-s": crop_green_pepper_s,
  "corn": crop_corn,
  "mandarin-orange": crop_mandarin_orange,
};

export default function SP2Info({
  houseName,
  cropName,
  plantingDate,
  displaySetting
}) {

  return (
    <div className="sp2-info-container">
      <div className="sp2-info-title">
        <div className="sp2-info-title-icon">
          <img src={ICONS[cropName]} alt="作物画像" width="100%" />
        </div>
        <div className="sp2-info-title-text">
          作物生理生態情報
        </div>
      </div>
      <div className="sp2-info-content">
        <div className="sp2-info-list-area">
          <SP2InfoList
            houseName={houseName}
            cropName={cropName}
            plantingDate={plantingDate}
            displaySetting={displaySetting}
          />
        </div>
        <div className="sp2-info-crop-area">
          <img src={CROPS[cropName]} alt={cropName} width="100%" />
        </div>
      </div>
    </div>
  );
};
