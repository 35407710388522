import { Meter } from "grommet";

import useLatestSP2Info from "../../hooks/use-latest-sp2-info";
import { useCanopy as _useCanopy } from "../../utils/displaySettingUtil";
import { displayValue } from "../../utils/displayUtil";

import "./SP2InfoListItem.css";

import icon_fresh_weight from "../../assets/sp2_info/sp2_info_icon_fresh_weight_2.png";
import icon_fruit_load from "../../assets/sp2_info/sp2_info_icon_fruit_load_2.png";
import icon_lai from "../../assets/sp2_info/sp2_info_icon_lai_2.png";
import icon_leaf_length from "../../assets/sp2_info/sp2_info_icon_leaf_length_2.png";
import icon_leaf_temperature from "../../assets/sp2_info/sp2_info_icon_leaf_temperature_2.png";
import icon_number_of_blooms from "../../assets/sp2_info/sp2_info_icon_number_of_blooms_2.png";
import icon_photosynthetic_rate from "../../assets/sp2_info/sp2_info_icon_photosynthetic_rate_2.png";
import icon_transpiration_rate from "../../assets/sp2_info/sp2_info_icon_transpiration_rate_2.png";
import icon_flowers from "../../assets/sp2_info/sp2_info_icon_flowers_2.png";
import icon_fruits_bell_pepper from "../../assets/sp2_info/sp2_info_icon_fruits_bell_pepper_2.png";
import icon_fruits_corn from "../../assets/sp2_info/sp2_info_icon_fruits_corn_2.png";
import icon_fruits_cucumber from "../../assets/sp2_info/sp2_info_icon_fruits_cucumber_2.png";
import icon_fruits_eggplant from "../../assets/sp2_info/sp2_info_icon_fruits_eggplant_2.png";
import icon_fruits_grape from "../../assets/sp2_info/sp2_info_icon_fruits_grape_2.png";
import icon_fruits_green_pepper from "../../assets/sp2_info/sp2_info_icon_fruits_green_pepper_2.png";
import icon_fruits_green_pepper_s from "../../assets/sp2_info/sp2_info_icon_fruits_green_pepper_s_2.png";
import icon_fruits_mandarin_orange from "../../assets/sp2_info/sp2_info_icon_fruits_mandarin_orange_2.png";
import icon_fruits_melon from "../../assets/sp2_info/sp2_info_icon_fruits_melon_2.png";
import icon_fruits_strawberry from "../../assets/sp2_info/sp2_info_icon_fruits_strawberry_2.png";
import icon_fruits_tomato from "../../assets/sp2_info/sp2_info_icon_fruits_tomato_2.png";

const STYLES = {
  fresh_weight: {
    displayName: "新鮮重",
    unit: "g/m2",
    icon: icon_fresh_weight,
    color: "#01A99D",
    meter: {
      min: 0,
      max: 2500,
    },
    digits: 0,
  },
  fruit_load: {
    displayName: "着果負担",
    unit: "個/m2",
    icon: icon_fruit_load,
    color: "#93278F",
    meter: {
      min: 0,
      max: 15,
    },
    digits: 1,
  },
  lai: {
    displayName: "LAI",
    unit: "m2/m2",
    icon: icon_lai,
    color: "#39B54A",
    meter: {
      min: 0,
      max: 6,
    },
    digits: 1,
  },
  leaf_length: {
    displayName: "草丈",
    unit: "cm",
    icon: icon_leaf_length,
    color: "#FF7BAC",
    meter: {
      min: 0,
      max: 50,
    },
    digits: 1,
  },
  leaf_temperature: {
    displayName: "葉温",
    unit: "℃",
    icon: icon_leaf_temperature,
    color: "#D4686C",
    meter: {
      min: 0,
      max: 50,
    },
    digits: 1,
  },
  number_of_blooms: {
    displayName: "開花数",
    unit: "個/m2",
    icon: icon_number_of_blooms,
    color: "#FF7BAC",
    meter: {
      min: 0,
      max: 3,
    },
    digits: 1,
  },
  photosynthetic_rate: {
    displayName: "光合成速度",
    unit: "µmol/m2/s",
    icon: icon_photosynthetic_rate,
    color: "#FF1D25",
    meter: {
      min: -5,
      max: 40,
    },
    digits: 1,
  },
  transpiration_rate: {
    displayName: "蒸散速度",
    unit: "mmol/m2/s",
    icon: icon_transpiration_rate,
    color: "#3FA9F5",
    meter: {
      min: 0,
      max: 5,
    },
    digits: 1,
  },
  flowers: {
    displayName: "開花数",
    unit: "個",
    icon: icon_flowers,
    color: "#FF7BAC",
    meter: {
      min: 0,
      max: 0,
    },
    digits: 1,
  },
  fruits: {
    displayName: "着果数",
    unit: "個",
    icon: {
      "bell-pepper": icon_fruits_bell_pepper,
      "corn": icon_fruits_corn,
      "cucumber": icon_fruits_cucumber,
      "eggplant": icon_fruits_eggplant,
      "grape": icon_fruits_grape,
      "green-pepper": icon_fruits_green_pepper,
      "green-pepper-s": icon_fruits_green_pepper_s,
      "mandarin-orange": icon_fruits_mandarin_orange,
      "melon": icon_fruits_melon,
      "strawberry": icon_fruits_strawberry,
      "tomato": icon_fruits_tomato,
    },
    color: {
      "bell-pepper": "#FF931D",
      "corn": "#459B6C",
      "cucumber": "#459B6C",
      "eggplant": "#B78DDB",
      "grape": "#B78DDB",
      "green-pepper": "#459B6C",
      "green-pepper-s": "#459B6C",
      "mandarin-orange": "#FBB03B",
      "melon": "#459B6C",
      "strawberry": "#FF777B",
      "tomato": "#FF777B",
    },
    meter: {
      min: 0,
      max: 0,
    },
    digits: 1,
  }
};

const isCanopy = (displaySetting, infoName) => {
  if (infoName === "photosynthetic_rate" || infoName === "transpiration_rate") {
    return _useCanopy(displaySetting);
  }
  return false;
};

const BaseComponent = ({ icon, displayName, meterMax, meterValue, color, value, digits, unit }) => (
  <li className="sp2-info-list-item-container">
    <div className="sp2-info-list-item-icon">
      <img src={icon} alt={displayName} width="100%" />
    </div>
    <div className="sp2-info-list-item-content">
      <div className="sp2-info-list-item-name">
        {displayName}
      </div>
      <div className="sp2-info-list-item-meter">
        <Meter
          background="#DADADA"
          size="full"
          thickness="small"
          max={meterMax}
          value={meterValue}
          color={color}
        />
      </div>
      <div
        className="sp2-info-list-item-value"
        style={{ color }}
      >
        {displayValue(value, digits)}
        <p className="sp2-info-list-item-value-unit">{unit}</p>
      </div>
    </div>
  </li>
);

export default function SP2InfoListItem({ infoName, groupName, houseName, cameraName, cropName, plantingDate, displaySetting }) {

  const { value } = useLatestSP2Info(infoName, groupName, houseName, cameraName, cropName, plantingDate, isCanopy(displaySetting, infoName));

  if (infoName === "flowers_and_fruits") {
    return (
      <>
        <BaseComponent
          icon={STYLES["flowers"].icon}
          displayName={STYLES["flowers"].displayName}
          meterMax={0}
          meterValue={0}
          color={STYLES["flowers"].color}
          value={value && value.flowers}
          digits={STYLES["flowers"].digits}
          unit={STYLES["flowers"].unit}
        />
        <BaseComponent
          icon={STYLES["fruits"].icon[cropName]}
          displayName={STYLES["fruits"].displayName}
          meterMax={0}
          meterValue={0}
          color={STYLES["fruits"].color[cropName]}
          value={value && value.unripe_fruits + value.ripe_fruits}
          digits={STYLES["fruits"].digits}
          unit={STYLES["fruits"].unit}
        />
      </>
    );
  } else {
    const { icon, displayName, color, digits, unit } = STYLES[infoName];
    const meterValue = value - STYLES[infoName].meter.min;
    const meterMax = STYLES[infoName].meter.max - STYLES[infoName].meter.min;
    return (
      <BaseComponent
        icon={icon}
        displayName={displayName}
        meterMax={meterMax}
        meterValue={meterValue}
        color={color}
        value={value}
        digits={digits}
        unit={unit}
      />
    );
  }
}
