import { format } from "date-fns";
import CurrentValueEnvInfoList from "./CurrentValueEnvInfoList";
import CurrentValueSP2InfoList from "./CurrentValueSP2InfoList";
import useLatestEnvInfo from "../../hooks/use-latest-env-info";
import "./CurrentValue.css";

import icon_eggplant from "../../assets/title_icon_eggplant.png";
import icon_chinese_chive from "../../assets/title_icon_chinese_chive.png";
import icon_bell_pepper from "../../assets/title_icon_bell_pepper.png";
import icon_tomato from "../../assets/title_icon_tomato.png";
import icon_melon from "../../assets/title_icon_melon.png";
import icon_grape from "../../assets/title_icon_grape.png";
import icon_spinach from "../../assets/title_icon_spinach.png";
import icon_wasabi from "../../assets/title_icon_wasabi.png";
import icon_strawberry from "../../assets/title_icon_strawberry.png";
import icon_cucumber from "../../assets/title_icon_cucumber.png";
import icon_green_pepper from "../../assets/title_icon_green_pepper.png";
import icon_green_pepper_s from "../../assets/title_icon_green_pepper_s.png";
import icon_corn from "../../assets/title_icon_corn.png";
import icon_mandarin_orange from "../../assets/title_icon_mandarin_orange.png";

const ICONS = {
  "eggplant": icon_eggplant,
  "chinese-chive": icon_chinese_chive,
  "bell-pepper": icon_bell_pepper,
  "tomato": icon_tomato,
  "melon": icon_melon,
  "grape": icon_grape,
  "spinach": icon_spinach,
  "wasabi": icon_wasabi,
  "strawberry": icon_strawberry,
  "cucumber": icon_cucumber,
  "green-pepper": icon_green_pepper,
  "green-pepper-s": icon_green_pepper_s,
  "corn": icon_corn,
  "mandarin-orange": icon_mandarin_orange,
};

export default function CurrentValue({
  houseName,
  cropName,
  plantingDate,
  displaySetting
}) {

  const { timestamp: latestTimestamp } = useLatestEnvInfo("air_temperature", houseName, plantingDate);

  return (
    <div className="current-value-container">
      <div className="current-value-title">
        <div className="current-value-title-icon">
          <img src={ICONS[cropName]} alt="作物画像" width="100%" />
        </div>
        <div className="current-value-title-text">現在値</div>
        {latestTimestamp && <span className="current-value-timestamp">{`${format(latestTimestamp, "yyyy/MM/dd HH:mm")} 時点`}</span>}
      </div>
      <div className="current-value-content">
        <div className="current-value-list-area">
          <CurrentValueEnvInfoList houseName={houseName} plantingDate={plantingDate} />
        </div>
        <div className="current-value-list-area">
          <CurrentValueSP2InfoList
            houseName={houseName}
            cropName={cropName}
            plantingDate={plantingDate}
            displaySetting={displaySetting}
          />
        </div>
      </div>
    </div>
  );
};
