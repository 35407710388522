import { useState, useEffect, useCallback } from "react";
import client from "../api/client";

const fetch = async (infoName, houseName, startDate) => {
  const params = {
    house_name: houseName,
    start: startDate && startDate.toISOString(),
    select: "latest"
  };
  try {
    const { status, statusText, data } = await client.get(`/sp2/db/${infoName}`, { params });
    if (status === 200) return data[0];
    console.log(status, statusText);
  } catch (error) {
    throw error;
  }
}

const fetchFlowersAndFruits = async (houseName, startDate) => {
  const params = {
    house_name: houseName,
    start: startDate && startDate.toISOString(),
    select: "latest",
    targets: [
      "flowers",
      "unripe_fruits",
      "ripe_fruits",
    ]
  };

  try {

    const { status, statusText, data } = await client.get("/sp2/db", { params });

    if (status !== 200) return console.log(status, statusText);

    return Object.entries(data).reduce((result, [infoName, item]) => {
      if (item instanceof Array && item.length > 0) {
        result.timestamp = item[0].timestamp;
        result.value[infoName] = item[0].value;
      } else {
        result.value[infoName] = null;
      }
      return result;
    }, {
      timestamp: null,
      value: {
        flowers: null,
        unripe_fruits: null,
        ripe_fruits: null,
      },
    });

  } catch (error) {
    throw error;
  }
}

export default function useLatestSP2DBInfo(infoName, houseName, startDate = undefined) {

  const [timestamp, setTimestamp] = useState(null);
  const [value, setValue] = useState(null);

  const handleSetValue = useCallback(() => {
    if (infoName === "flowers_and_fruits") {
      fetchFlowersAndFruits(houseName, startDate)
        .then(data => {
          if (data) {
            setTimestamp(new Date(data.timestamp));
            setValue(data.value);
          }
        })
        .catch(error => console.log(error));
    } else {
      fetch(infoName, houseName, startDate)
        .then(data => {
          if (data) {
            setTimestamp(new Date(data.timestamp));
            setValue(data.value);
          }
        })
        .catch(error => console.log(error));
    }
  }, [infoName, houseName, startDate]);

  useEffect(() => {
    handleSetValue();
    const pollingInterval = 1000 * Number.parseInt(process.env.REACT_APP_POLLING_INTERVAL);
    const interval = setInterval(handleSetValue, pollingInterval);
    return () => {
      clearInterval(interval);
      setTimestamp(null);
      setValue(null);
    };
  }, [handleSetValue]);

  return { timestamp, value };
};
