import { useState, useEffect, useCallback } from "react";
import sp2Api from "../api/sp2Api";

export default function useLatestSP2Info(infoName, groupName, houseName, cameraName, cropName, startDate = undefined, isCanopy = false) {

  const [timestamp, setTimestamp] = useState(null);
  const [value, setValue] = useState(null);

  const handleSetValue = useCallback(() => {
    const start = startDate && startDate.toISOString();
    const end = new Date().toISOString()
    sp2Api.fetch(infoName, groupName, houseName, cameraName, cropName, start, end, "latest", isCanopy)
      .then(sp2Response => {
        if (sp2Response[0]) {
          const data = sp2Response[0];
          setTimestamp(new Date(data.timestamp));
          setValue(data.value);
        }
      })
      .catch(error => console.log(error));
  }, [ infoName, groupName, houseName, cameraName, cropName, startDate, isCanopy ]);

  useEffect(() => {
    handleSetValue();
    const pollingInterval = 1000 * Number.parseInt(process.env.REACT_APP_POLLING_INTERVAL);
    const interval = setInterval(handleSetValue, pollingInterval);
    return () => {
      clearInterval(interval);
      setTimestamp(null);
      setValue(null);
    };
  }, [ handleSetValue ]);

  return { timestamp, value };
};
